<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-dock-top</v-icon>
      </v-btn>
    </template>

    <v-list
      :tile="false"
      flat
      nav
    >
      <template v-for="(p, i) in adminMenu">

        <v-divider
          v-if="p.divider"
          :key="`divider-${i}`"
          class="mb-2 mt-2"
        />

        <app-bar-item
          v-else
          :key="`item-${i}`"
        >
          <router-link
            :to="{ name: p.routeName}"
            class="admin-menu"
          >
            {{ p.title }}
          </router-link>
        </app-bar-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: 'AdminMenu',

    data: () => ({
      adminMenu: [
        { title: 'Item Category', routeName: 'Admins/ItemCategory' },
        { title: 'Item Type', routeName: 'Item Type' },
        { title: 'Item Property (Not Ready)', routeName: 'Item Property' },
        { divider: true },
        { title: 'Equipment Model', routeName: 'Equipment Model' },
        { title: 'Testing Report', routeName: 'Testing Report' },
        { divider: true },
        { title: 'System Settings', routeName: 'Admins/SystemSetting' },
        { title: 'User Management', routeName: 'Admins/UserManagement' },
      ],
    }),
    /*
    methods: {
      jumperTo (name) {
        this.$router.push({ name: name }).catch(() => {})
      },
    },
    */
  }
</script>
<style>
  .admin-menu:link {
    color: grey;
    text-decoration: none;
    display: inline-block;
    text-decoration: none;
  }
</style>
